<template>
	<div>
		<div class="table-responsive">
			<table class="table b-table table-fixed table-bordered">
				<thead>
					<tr>
						<th>
							<div class="d-flex" style="gap: 10px;">
								<b-img class="filter-image" :class="filter.order_type == 'asc' ? 'rotate-180' : ''"
									:src="require('@/assets/images/icons/Icon-order-list-down.svg')"
									alt="Icon-order-list-down" role="button"
									@click="filter.order_type == 'asc' ? filter.order_type = 'desc' : filter.order_type = 'asc'" />
								<span>No</span>
							</div>
						</th>
						<th>Tanggal</th>
						<th>Nama</th>
						<th>Email</th>
						<th>Points</th>
						<th>Message</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="isLoadingComponent">
						<td colspan="12" class="text-center">
							<b-spinner class="mb-1" variant="primary" /><br>
							Loading...
						</td>
					</tr>
					<tr v-for="item, index in feedbackList" :key="item.id">
						<td>
							{{ (currentPageList - 1) * 10 + index + 1 }}
						</td>
						<td>
							{{ formatDate(item.created_at) || '-' }}
						</td>
						<td>
							{{ item.creator.creator_name || '-' }}
						</td>
						<td>
							{{ item.creator.creator_email || '-' }}
						</td>
						<td>
							{{ item.points || '-' }}
						</td>
						<td>
							{{ item.message || '-' }}
						</td>
					</tr>
					<tr v-if="resultData.total == 0 && !isLoadingComponent">
						<td colspan="13" class="text-center">
							Data is empty.
						</td>
					</tr>
				</tbody>
			</table>

			<div v-if="resultData.total > 0" class="m-1">
				<div class="row">
					<div class="col mb-1">
						<small>Showing {{ resultData.from }} to {{ resultData.to }} from {{ resultData.total }}</small>
					</div>
					<div class="col">
						<pagination :data="resultData" :limit="4" align="right" @pagination-change-page="getData" />
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {
	BTable,
	BSpinner,
	BBadge,
	BImg,
	BDropdown,
	BDropdownItem,
} from 'bootstrap-vue'
import _ from 'lodash'
export default {
	components: {
		BTable,
		BSpinner,
		BBadge,
		BImg,
		BDropdown,
		BDropdownItem,
	},
	props: {
		feedback: {
			type: '',
		},
		result: {
			type: '',
		},
		isLoading: {
			type: Boolean,
		},
		currentPage: {
			type: Number,
		},
		getData: {
			type: Function,
		},
		deleteItem: {
			type: Function,
		},
		formatDate: {
			type: Function,
		}
	},
	watch: {
		isLoading(value) {
			this.isLoadingComponent = value
		},
		feedback(value) {
			this.feedbackList = value
		},
		currentPage(value) {
			this.currentPageList = value
		},
		result(value) {
			this.resultData = value
		},
		filter: {
			handler(value) {
				this.$emit('filter', value)
			},
			deep: true
		}
	},
	data() {
		return {
			isLoadingComponent: true,
			currentPageList: 1,
			resultData: {},
			feedbackList: [],
			filter: {
				order_type: 'desc'
			},
			path: process.env.URL_LANDING_PAGE
		}
	},
	computed: {
		rows() {
			return this.feedbackList.length
		}
	},
}
</script>

<style>
.text-detail {
	width: 350px;
}

.table tbody tr:not([class*=table-]) td {
	vertical-align: top;
}
</style>